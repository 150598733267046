<template>
    <div class="product_detil">
        <!-- 顶部导航 -->
        <div class="top_advBox">
            <TopAdv />
        </div>
        <!-- 导航下方图片背景 -->
        <div class="img_box">
            <img src="../../../assets/productImg/banner.jpg" alt="" />
        </div>
        <div v-if="detailText" class="container">
            <!-- 首页->产品详情导航 -->
            <div class="product_adv">
                <div>
                    <span @click="goIndex" class="return_page">首页</span><span>></span
                ><span class="return_page" @click="goProductPage">星火实验室</span>
                </div>
                <div class="right_time">
                    <i class="el-icon-time"></i>

                    <span class="time_text">{{ detailText.time }}</span>
                </div>
            </div>
            <!-- 右侧方块 -->
            <div class="product_icon" @click="goProductPage">
                <i class="el-icon-s-grid"></i>
            </div>
            <!-- 正文 -->
            <div v-loading='loading'>
                <h2>{{ detailText.name }}</h2>
                <div class="detail_text" v-html="detailText.details"></div>
            </div>
        </div>
        <BottomIntroduce></BottomIntroduce>
    </div>
</template>

<script>
    import TopAdv from "../../../components/TopAdv";
    import BottomIntroduce from "../../../components/BottomIntroduce";
    import {getLaboratoryList, getLaboratoryNews} from "../../../api";
    export default {
        name: "ProductDetil",
        data() {
            return {
                detailText: null,
                loading:true
            };
        },
        components: {
            TopAdv,
            BottomIntroduce
        },
        created() {
            this.$store.commit("modifyVuexAdvActive", { vuexAdvActive: 2 });
            //   拿到id获取文章详情
            if(this.$route.query.type==0){
                getLaboratoryList(this.$route.query.id).then((res) => {
                    this.loading=false
                    this.detailText = res.data;
                });
            }else{
                getLaboratoryNews(this.$route.query.id).then((res) => {
                    this.loading=false
                    this.detailText = res.data;
                });
            }
        },
        methods: {
            //   返回首页
            goIndex() {
                this.$router.push({ path: "/" });
            },
            // 点击详情返回产品页
            goProductPage() {
                this.$router.push({
                    path: "/laboratory",
                });
            },
        },
    };
</script>

<style scoped lang="less">
    .product_detil {
        min-width: 1200px;
    }
    .img_box {
        margin-top: 75px;
        img {
            width: 100%;
        }
    }
    .detail_text {
        color: #333 !important;
        line-height: 1.7;
        padding-bottom: 60px;
        &:deep(img) {
            border-radius: 6px;
            margin-top: 10px;
        }
    }
    .product_adv {
        padding: 20px 0;
        color: #8d8d8d;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #c8c8c8;
    }
    .product_icon {
        position: relative;
        height: 40px;
        i {
            position: absolute;
            right: 0;
            top: 30%;
            color: #999;
            font-size: 26px;
            cursor: pointer;
        }
    }
    h2 {
        text-align: center;
        margin-bottom: 30px;
    }
    .return_page {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .right_time {
        i {
            font-size: 18px;
        }
        .time_text {
            padding-left: 10px;
        }
    }
    .container {
        border-bottom: 1px solid #999;
        margin-bottom: 40px;
    }
</style>
